import React, { useCallback, useState } from 'react'
import useTemplateContext from '../../hooks/useTemplateContext'
import { useNavigate } from 'react-router-dom'
import { convertToLocaleDate, convertToLocaleTime } from '../../utils/timezone'
import { Column, Table, TableButton } from '../../DAIComponents/Table/DAITable'
import { Template } from '../../types/Template'
import RemoveModal from 'src/DAIComponents/Modal/RemoveModal'

export default function TemplatesTable() {
  const {
    templatesLoading: loading,
    templates,
    deleteTemplate,
  } = useTemplateContext()

  const navigate = useNavigate()
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [id, setId] = useState<string>('')

  const onEdit = useCallback(
    (id: string) => {
      navigate(`/dashboard/templates/edit/${id}`)
    },
    [navigate]
  )

  const onDelete = useCallback((id: string) => {
    setId(id)
    setRemoveModal(true)
  }, [])
  const onCreate = useCallback(() => {
    navigate(`/dashboard/templates/edit/${0}`)
  }, [navigate])

  return (
    <>
      {removeModal && (
        <RemoveModal
          isOpen={removeModal}
          closeModal={() => {
            setRemoveModal(false)
            setId('')
          }}
          openModal={() => setRemoveModal(true)}
          onDelete={() => {
            deleteTemplate(id)
            setRemoveModal(false)
            setId('')
          }}
          title={'Delete Template'}
          text={
            'Are you sure you want to remove this template? ' +
            'All of the data associated with this template will be permanently removed.'
          }
        />
      )}
      <Table
        loading={loading}
        onAdd={onCreate}
        data={templates}
        title={'Templates'}
        description={'A list of all your templates.'}
      >
        <Column title="Template Title">
          {(template: Template) => template.name}
        </Column>
        <Column title="Created">
          {(template: Template) => convertToLocaleDate(template.createdAt)}
        </Column>
        <Column title="Updated">
          {(template: Template) => convertToLocaleTime(template.updatedAt)}
        </Column>
        <Column title="">
          {(template: Template) => (
            <TableButton
              editButton={true}
              onClick={() => onEdit(template.id)}
            />
          )}
        </Column>
        <Column title="">
          {(template: Template) => (
            <TableButton
              deleteButton={true}
              onClick={() => onDelete(template.id)}
            />
          )}
        </Column>
      </Table>
    </>
  )
}
