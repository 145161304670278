import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { AppRoutes } from 'src/utils/constants'
import DAIButton from 'src/DAIComponents/Input/Button/DAIButton'
import DAIEmailInput from 'src/DAIComponents/Input/Email/DAIEmailInput'

const forgotPasswordSchema = z.object({
  email: z.string().email('Invalid email format'),
})

type ForgotPasswordForm = z.infer<typeof forgotPasswordSchema>

export default function ForgotPassword() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordForm>({
    resolver: zodResolver(forgotPasswordSchema),
  })

  const goBack = useCallback(() => {
    navigate(AppRoutes.login)
  }, [])

  const onSubmit = (data: ForgotPasswordForm) => {
    // TODO: Implement password reset functionality
    console.log('Reset password for:', data.email)
  }

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={'/images/logo.svg'}
                alt="DeliverAI"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Reset your password
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                <a
                  onClick={goBack}
                  className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                >
                  Go back to login
                </a>
              </p>
            </div>
            <div className="mt-10">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <DAIEmailInput
                    name="email"
                    label="Email address"
                    register={register('email')}
                    error={errors.email?.message}
                    required
                  />
                  <p className="mt-1 text-sm leading-6 text-gray-400">
                    Enter the email address associated with your account, and
                    we'll email you a link to reset your password.
                  </p>
                </div>
                <DAIButton
                  buttonType="submit"
                  text="Reset Password"
                  variant="primary"
                />
              </form>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="image-container">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/images/Jewelry_Store.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
