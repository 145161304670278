import React, { useCallback, useEffect, useState } from 'react'
import { useUsermaven } from '@usermaven/react'
import CreateAutomationSidePanel from './CreateAutomationSidePanel'
import UseAIModal from './UseAIModal'

export interface Box {
  id: number
  text: string
}

export interface Connection {
  from: number
  to: number
  label: string
}

export default function CreateAutomation() {
  const [modalOpen, setModalOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)

  // const openAIPopup = useCallback(async () => {
  //   setModalOpen(true)
  //   await track('AI_Popup_Opened')
  // }, [])

  const createNewAutomation = useCallback(() => {
    setCreateOpen(true)
    return
  }, [])

  const [boxes, setBoxes] = useState<Box[]>([])
  const [connections, setConnections] = useState<Connection[]>([])
  const [title, setTitle] = useState<string>('')

  const addConnection = (from: number, to: number, label: string) => {
    setConnections([...connections, { from, to, label }])
  }


  useEffect(() => {
    // set up fake data to show boxes and connections
    const box1: Box = {
      id: 1,
      text: 'When Anniversary is Incoming - 5 days before',
    }
    const box2: Box = {
      id: 2,
      text: 'Anniversary Email Reminder Template',
    }

    setBoxes([box1, box2])
    setConnections([{ from: 1, to: 2, label: 'Send Email' }])
    setTitle('Anniversary Automation')
  }, [])

  return (
    <>
      <UseAIModal
        openModal={() => setModalOpen(true)}
        closeModal={() => setModalOpen(false)}
        isOpen={modalOpen}
      />

      {createOpen && (
        <CreateAutomationSidePanel
          onClose={() => {
            setCreateOpen(false)
          }}
          addConnection={addConnection}
          setAutomationTitle={setTitle}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Update Automations
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Set up automations to send emails to your customers based on certain
            conditions.
          </p>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          {/*TODO Bring this back after prezi */}
          {/*<button*/}
          {/*  onClick={openAIPopup}*/}
          {/*  type="button"*/}
          {/*  className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"*/}
          {/*>*/}
          {/*  Use AI*/}
          {/*</button>*/}
          {/*<div className="ml-6 h-6 w-px bg-gray-300" />*/}
          <button
            onClick={createNewAutomation}
            type="button"
            className="ml-6 rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Create
          </button>
        </div>
      </div>
      <div className="relative flex flex-col items-center mt-12 pr-64">
        <h2 className="text-lg font-semibold pb-4">Automation Flow</h2>
        <h2 className="text-md font-semibold pb-8">{title}</h2>
        {boxes.map((box, index) => (
          <div key={box.id} className="flex flex-col items-center">
            <div
              className="p-4 bg-white border shadow rounded mb-2"
              style={{ width: '250px', height: '100px' }}
            >
              {box.text}
            </div>
            {index < boxes.length - 1 && (
              <div className="flex flex-col items-center mb-2">
                <svg height="50" width="100">
                  <defs>
                    <marker
                      id={`arrow-${index}`}
                      markerWidth="10"
                      markerHeight="10"
                      refX="10"
                      refY="3"
                      orient="auto"
                      markerUnits="strokeWidth"
                    >
                      <path d="M0,0 L0,6 L9,3 z" fill="#000" />
                    </marker>
                  </defs>
                  <line
                    x1="50"
                    y1="0"
                    x2="50"
                    y2="50"
                    stroke="black"
                    strokeWidth="2"
                    markerEnd={`url(#arrow-${index})`}
                  />
                </svg>
                {connections.map((conn, connIndex) => {
                  if (
                    conn.from === box.id &&
                    conn.to === boxes[index + 1]?.id
                  ) {
                    return (
                      <span key={connIndex} className="text-center mt-1">
                        {conn.label}
                      </span>
                    )
                  }
                  return null
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  )
}
