import React from 'react'
import { SaleContextProvider } from 'src/contexts/saleContext'
import Loading from 'src/DAIComponents/Common/Loading'
import useProfileContext from 'src/hooks/useProfileContext'
import Notes from './Notes'
import ProfileForm from './ProfileForm'
import ProfileSales from './ProfileSales'

const ProfilePage = () => {
  const { isLoading } = useProfileContext()

  if (isLoading) {
    return <Loading full={true} />
  }
  return (
    <SaleContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <ProfileForm />
        <div className="mt-4 overflow-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="rounded min-h-[300px] border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <ProfileSales />
            </div>
            <div className="rounded min-h-[300px] border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <Notes />
            </div>
            {/*<div className="bg-gray-200 p-4 rounded min-h-[300px]">*/}
            {/*  Appointments*/}
            {/*</div>*/}
            {/*<div className="rounded min-h-[300px] border-b border-gray-200 bg-white px-4 py-5 sm:px-6">*/}
            {/*  Activity*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </SaleContextProvider>
  )
}

export default ProfilePage
