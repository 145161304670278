import React from 'react'

export interface DAILabelProps {
  name: string
  required?: boolean
}

export default function DAILabel({ name, required }: DAILabelProps) {
  return (
    <label
      htmlFor={name}
      className="block text-sm font-medium text-gray-700"
    >
      {name}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
  )
}
