import { PencilIcon } from '@heroicons/react/24/outline'
import { useCallback, useState } from 'react'
import { truncate } from '../../utils/textUtils'
import CreateSaleModal from '../Sales/CreateSaleModal'
import { Sale } from '../../types/Sale'
import useProfileContext from '../../hooks/useProfileContext'
import CreateProfileSaleModal from './CreateProfileSaleModal'

const ProfileSales = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [initialData, setInitialData] = useState<Sale | undefined>()
  const { profileData, sales } = useProfileContext()

  const onCreateNew = useCallback(() => {
    // TODO this should set the contact id automatically so the user doesn't have to select it
    setModalOpen(true)
  }, [profileData])

  const handleEditSale = useCallback(
    (id: string) => {
      setInitialData(sales?.find(item => item.id === id)!)
      setModalOpen(true)
    },
    [sales]
  )

  const openModal = useCallback(() => {
    setModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalOpen(false)
  }, [])

  return (
    <div>
      {modalOpen && (
        <CreateProfileSaleModal
          isOpen={modalOpen}
          openModal={openModal}
          closeModal={closeModal}
          initialData={initialData}
        />
      )}
      <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap pb-6">
        <div className="ml-4 mt-2">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Sales
          </h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <button
            type="button"
            className="relative inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={onCreateNew}
          >
            Create new sale
          </button>
        </div>
      </div>
      <ul role="list" className="space-y-3 overflow-auto h-[300px]">
        {sales?.length === 0 && (
          <span className={'text-gray-700'}>No sales found</span>
        )}
        {sales?.map(item => (
          <li
            key={item.id}
            className="group relative overflow-hidden rounded-md bg-white px-6 py-4 shadow ring-1 ring-gray-200 m-2"
          >
            <div className="absolute top-3 right-3">
              <button
                type="button"
                className="p-1 text-gray-400 hover:text-gray-600"
                aria-label="Edit"
                onClick={() => handleEditSale(item.id)}
              >
                <PencilIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <h4 className="text-base font-semibold leading-6 text-gray-900">
              {item.invoiceNumber}
            </h4>
            <p className="mt-1 text-sm text-gray-700">
              {truncate(item.productCategory.name, 20)}: ${item.cost}
            </p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProfileSales
