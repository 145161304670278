import { createContext, PropsWithChildren, useCallback, useMemo } from 'react'
import { Product } from '../types/Product'
import { useUsermaven } from '@usermaven/react'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNotifications } from './notificationContext'
import { DAIRequest } from '../utils/DAIRequest'

type ProductContextType = {
  loading: boolean
  products: Product[]
  createProduct: (data: Product) => void
  editProduct: (data: Product) => void
  deleteProduct: (id: string) => void
}

const ProductContext = createContext<ProductContextType | undefined>(undefined)

export const ProductContextProvider = ({ children }: PropsWithChildren) => {
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()

  const {
    isLoading: loading,
    data: productsState,
    refetch,
  } = useQuery<Product[]>({
    queryKey: ['products'],
    queryFn: () =>
      DAIRequest(
        'GET',
        '/api/v1/product-categories/getMyProductCategories'
      ).then(res => res.data),
    initialData: [],
  })

  const products = useMemo(() => {
    if (!searchQuery) {
      return productsState
    }
    return productsState?.filter(product =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  }, [productsState, searchQuery])

  const createProductMutation = useMutation({
    mutationFn: (data: Product) => {
      return DAIRequest(
        'POST',
        '/api/v1/product-categories/createProductCategory',
        {
          body: data,
        }
      )
    },
    onSuccess: async () => {
      await track('Product_Created')
      addNotification('success', 'Product Created')
      await refetch()
    },
    onError: async (error: any) => {
      await track('Product_Created_Error')
      const message = error?.message || 'Error creating product'
      addNotification('error', message)
    },
  })

  const editProductMutation = useMutation({
    mutationFn: (data: Product) => {
      return DAIRequest(
        'patch',
        '/api/v1/product-categories/updateProductCategory/:id',
        {
          body: data,
          params: { id: data.id },
        }
      )
    },
    onSuccess: async () => {
      await track('Product_Updated')
      addNotification('success', 'Product Updated')
      await refetch()
    },
    onError: async (error: any) => {
      await track('Product_Updated_Error')
      const message = error?.message || 'Error updating product'
      addNotification('error', message)
    },
  })

  const deleteProductMutation = useMutation({
    mutationFn: (id: string) => {
      return DAIRequest('DELETE', '/api/v1/product-categories/:id', {
        params: { id },
      })
    },
    onSuccess: async () => {
      await track('Product_Deleted')
      addNotification('success', 'Product Deleted')
      await refetch()
    },
    onError: async (error: any) => {
      await track('Product_Deleted_Error')
      const message = error?.message || 'Error deleting product'
      addNotification('error', message)
    },
  })

  return (
    <ProductContext.Provider
      value={{
        loading,
        products,
        createProduct: createProductMutation.mutate,
        editProduct: editProductMutation.mutate,
        deleteProduct: deleteProductMutation.mutate,
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}

export default ProductContext
