import { useEffect, useCallback, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import Loading from '../../DAIComponents/Common/Loading'
import useTemplateContext from '../../hooks/useTemplateContext'
import DAIComboBox from '../../DAIComponents/Input/Combo/DAIComboBox'
import DAITextBox from '../../DAIComponents/Input/Text/DAITextBox'
import DAIButton from '../../DAIComponents/Input/Button/DAIButton'
import DAITextArea from '../../DAIComponents/Input/TextArea/DAITextArea'
import DAICheckBox from '../../DAIComponents/Input/Checkbox/DAICheckBox'

const templateSchema = z.object({
  name: z.string().min(1, 'Template name is required').max(100, 'Template name is too long'),
  subject: z.string().min(1, 'Subject is required').max(200, 'Subject is too long'),
  content: z.string().min(1, 'Content is required'),
  insertInSubject: z.boolean()
});

type TemplateForm = z.infer<typeof templateSchema>;

export default function Edit() {
  const {
    createTemplateMutation,
    updateTemplateMutation,
    setTemplateId,
    template: data,
    templateLoading: isLoading,
    templateId,
    placeholders,
    placeholdersLoading,
  } = useTemplateContext()

  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const lastCursorPosition = useRef<{ start: number; end: number }>({ start: 0, end: 0 });
  const [insertInSubject, setInsertInSubject] = useState(false);
  const subjectInputRef = useRef<HTMLInputElement | null>(null);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<TemplateForm>({
    resolver: zodResolver(templateSchema),
    defaultValues: {
      name: data?.name || '',
      subject: data?.subject || '',
      content: data?.content || '',
      insertInSubject: false,
    }
  })

  useEffect(() => {
    const id = window.location.pathname.split('/').pop()
    if (id !== undefined) {
      setTemplateId(id)
    }
  }, [])

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue('subject', data.subject)
      setValue('content', data.content)
    }
  }, [data, setValue])

  const onSubmit = handleSubmit((formData) => {
    if (templateId === '0') {
      createTemplateMutation.mutate({
        name: formData.name,
        subject: formData.subject,
        content: formData.content,
      })
    } else {
      updateTemplateMutation.mutate({
        id: templateId,
        name: formData.name,
        subject: formData.subject,
        content: formData.content,
      })
    }
  })

  // Save cursor position when textarea is focused or selection changes
  const handleTextAreaSelect = useCallback(() => {
    if (textAreaRef.current) {
      lastCursorPosition.current = {
        start: textAreaRef.current.selectionStart,
        end: textAreaRef.current.selectionEnd
      };
    }
  }, []);

  const handlePlaceholderChange = useCallback((placeholder: { id: string, name: string } | null) => {
    if (!placeholder) return;

    const insertText = `{{${placeholder.name}}}`;

    if (insertInSubject && subjectInputRef.current) {
      const input = subjectInputRef.current;
      const start = input.selectionStart || 0;
      const end = input.selectionEnd || 0;
      const currentValue = input.value;
      
      const newValue = currentValue.substring(0, start) + insertText + currentValue.substring(end);
      setValue('subject', newValue);
      
      setTimeout(() => {
        input.focus();
        const newPosition = start + insertText.length;
        input.setSelectionRange(newPosition, newPosition);
      }, 0);
    } else if (textAreaRef.current) {
      const content = textAreaRef.current.value;
      const { start, end } = lastCursorPosition.current;
      
      const newContent = content.substring(0, start) + insertText + content.substring(end);
      setValue('content', newContent);
      
      setTimeout(() => {
        if (textAreaRef.current) {
          const newPosition = start + insertText.length;
          textAreaRef.current.focus();
          textAreaRef.current.setSelectionRange(newPosition, newPosition);
          lastCursorPosition.current = { start: newPosition, end: newPosition };
        }
      }, 0);
    }
  }, [setValue, insertInSubject]);

  if (isLoading || placeholdersLoading) {
    return <Loading />
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
        <div className="px-4 py-4">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              {templateId === '0' ? 'Create Template' : 'Edit Template'}
            </h2>
            <DAIButton
              buttonType="submit"
              text="Save Template"
              variant="primary"
              onClick={onSubmit}
            />
          </div>
          
          <form onSubmit={onSubmit} className="grid grid-cols-1 gap-x-6 gap-y-8">
            <div>
              <DAITextBox
                name="name"
                label="Template Name"
                required
                register={register('name')}
                placeholder="Enter template name"
                error={errors.name?.message}
              />
            </div>

            <div className="flex items-end gap-4">
              <div className="flex-1">
                <DAIComboBox
                  name="placeholder"
                  label="Insert Placeholder"
                  data={placeholders}
                  field={{
                    value: {id: "-1", name: ""},
                    onChange: handlePlaceholderChange
                  }}
                  placeholder="Select a placeholder to insert"
                />
              </div>
              <div className="mb-2">
                <DAICheckBox
                  name="insertInSubject"
                  label="Insert in Subject"
                  register={register('insertInSubject')}
                  onChange={(checked) => setInsertInSubject(checked as boolean)}
                />
              </div>
            </div>

            <div>
              <DAITextBox
                name="subject"
                label="Email Subject"
                required
                register={register('subject')}
                placeholder="Enter email subject"
                error={errors.subject?.message}
                ref={(el) => {
                  register('subject').ref(el);
                  subjectInputRef.current = el;
                }}
              />
            </div>

            <div className="col-span-full">
              <DAITextArea
                name="content"
                label="Email Content"
                required
                register={register('content')}
                placeholder="Enter your template content here"
                error={errors.content?.message}
                minRows={10}
                ref={(el) => {
                  register('content').ref(el);
                  textAreaRef.current = el;
                }}
                onSelect={handleTextAreaSelect}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
