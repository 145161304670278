import React from 'react'
import UpdateInfoSection from './Sections/UpdateInfoSection'
import ChangePasswordSection from './Sections/ChangePasswordSection'

export default function Settings() {
  return (
    <div>
      {/* Settings forms */}
      <div className="divide-y divide-white/5">
        <UpdateInfoSection />
        <ChangePasswordSection />
        {/*<LogoutSessionsSection />*/}
        {/*<DeleteAccountSection />*/}
      </div>
    </div>
  )
}
