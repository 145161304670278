import React from 'react'
import { ContactContextProvider } from 'src/contexts/contactContext'
import ContactTable from './ContactTable'

export default function Contacts() {
  return (
    <ContactContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <ContactTable />
      </div>
    </ContactContextProvider>
  )
}
