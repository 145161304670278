import { Fragment, useCallback, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../../utils/className'
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase'
import DAILabel from '../../Common/DAILabel'
import { UseFormRegisterReturn } from 'react-hook-form'

type DAIComboBoxProps = Omit<DAIInputComponentBase, 'type' | 'register'> & {
  data: { id: string; name: string }[]
  searchable?: boolean
  register?: UseFormRegisterReturn
  field?: {
    value: { id: string; name: string }
    onChange: (value: { id: string; name: string }) => void
  }
}

export default function DAIComboBox({
  name,
  label,
  register,
  field,
  required,
  error,
  disabled,
  data,
  searchable = true,
  className = '',
  flexDirection = 'col',
}: DAIComboBoxProps) {
  const [query, setQuery] = useState('')

  const baseClasses = "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const filteredItems = query === ''
    ? data
    : data.filter(item =>
        item.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      );

  const handleChange = useCallback((value: { id: string; name: string }) => {
    field?.onChange?.(value);
  }, [field?.onChange]);

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={flexDirection === 'col' ? 'mt-2' : ''}>
        <Combobox
          value={field?.value}
          onChange={handleChange}
          disabled={disabled}
        >
          <div className="relative">
            <Combobox.Input
              className={`${baseClasses} ${errorClasses} ${disabledClasses}`}
              onChange={event => setQuery(event.target.value)}
              autoComplete="off"
              displayValue={(item: any) => item?.name || ''}
              disabled={!searchable || disabled}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>

            {filteredItems.length > 0 && (
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredItems.map(item => (
                  <Combobox.Option
                    key={item.id}
                    value={item}
                    className={({ active }) =>
                      classNames(
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                        active ? 'bg-blue-600 text-white' : 'text-gray-900'
                      )
                    }
                  >
                    {({ active, selected }) => (
                      <>
                        <span
                          className={classNames(
                            'block truncate',
                            selected ? 'font-semibold' : 'font-normal'
                          )}
                        >
                          {item.name}
                        </span>

                        {selected && (
                          <span
                            className={classNames(
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                              active ? 'text-white' : 'text-blue-600'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  )
} 