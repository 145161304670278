import { Fragment, useCallback, useEffect, useState, useRef } from 'react'
import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { classNames } from '../../../utils/className'
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase'
import DAILabel from '../../Common/DAILabel'
import { UseFormRegisterReturn } from 'react-hook-form'
import { useInfiniteQuery } from '@tanstack/react-query'
import { AllowedEndpoints, DAIRequest } from '../../../utils/DAIRequest'
import debounce from 'lodash/debounce'

type DAIVirtualComboBoxProps = Omit<DAIInputComponentBase, 'type' | 'register'> & {
  endpoint: AllowedEndpoints
  searchable?: boolean
  register?: UseFormRegisterReturn
  field?: {
    value: { id: string; name: string }
    onChange: (value: { id: string; name: string }) => void
  }
  itemsPerPage?: number
}

export default function DAIVirtualComboBox({
  name,
  label,
  register,
  field,
  required,
  error,
  disabled,
  endpoint,
  searchable = true,
  className = '',
  flexDirection = 'col',
  itemsPerPage = 50,
}: DAIVirtualComboBoxProps) {
  const [query, setQuery] = useState('')
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['virtualComboBox', endpoint, query],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await DAIRequest('GET', endpoint, {
        query: {
          page: pageParam,
          limit: itemsPerPage,
          ...(query ? { search: query } : {}),
        },
      })
      return {
        items: response.data,
        nextPage: response.data.length === itemsPerPage ? pageParam + 1 : undefined,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialPageParam: 1,
  })

  const items = data?.pages.flatMap(page => page.items) ?? []

  const debouncedSearch = debounce((value: string) => {
    setQuery(value)
  }, 300)

  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement
    if (
      !isFetchingNextPage &&
      hasNextPage &&
      target.scrollHeight - target.scrollTop <= target.clientHeight + 100
    ) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const baseClasses = "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300"
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : ""

  const handleChange = useCallback((value: { id: string; name: string }) => {
    field?.onChange?.(value)
  }, [field?.onChange])

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={flexDirection === 'col' ? 'mt-2' : ''}>
        <Combobox
          value={field?.value}
          onChange={handleChange}
          disabled={disabled}
          as="div"
        >
          {({ open }) => (
            <div className="relative">
              <Combobox.Input
                className={`${baseClasses} ${errorClasses} ${disabledClasses}`}
                onChange={(event) => {
                  debouncedSearch(event.target.value)
                }}
                autoComplete="off"
                displayValue={(item: any) => item?.name || ''}
                disabled={!searchable || disabled}
              />
              <Combobox.Button 
                className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
              >
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>

              <Combobox.Options 
                className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                onScroll={handleScroll}
              >
                {items.length === 0 ? (
                  <div className="px-4 py-2 text-sm text-gray-500">
                    No results found
                  </div>
                ) : (
                  items.map(item => (
                    <Combobox.Option
                      key={item.id}
                      value={item}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-blue-600 text-white' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span
                            className={classNames(
                              'block truncate',
                              selected ? 'font-semibold' : 'font-normal'
                            )}
                          >
                            {item.name}
                          </span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-blue-600'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
                {isFetchingNextPage && (
                  <div className="px-4 py-2 text-sm text-gray-500">
                    Loading more...
                  </div>
                )}
              </Combobox.Options>
            </div>
          )}
        </Combobox>
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  )
} 