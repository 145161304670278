import React from 'react';
import DAITextBox from '../Text/DAITextBox';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';

type DAINameInputProps = Omit<DAIInputComponentBase, 'type'>;

export default function DAINameInput(props: DAINameInputProps) {
  return (
    <DAITextBox
      {...props}
      type="text"
      validation={{
        required: props.required,
        pattern: /^[A-Za-z\s'-]+$/,
        customError: 'Please enter a valid name'
      }}
    />
  );
} 