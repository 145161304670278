import React from 'react'
import { TemplateContextProvider } from 'src/contexts/templateContext'
import TemplatesTable from './TemplatesTable'

export default function Templates() {
  return (
    <TemplateContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <TemplatesTable />
      </div>
    </TemplateContextProvider>
  )
}
