import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { LoginType } from 'src/contexts/authContext'
import Loading from 'src/DAIComponents/Common/Loading'
import DAIButton from 'src/DAIComponents/Input/Button/DAIButton'
import DAICheckBox from 'src/DAIComponents/Input/Checkbox/DAICheckBox'
import DAIEmailInput from 'src/DAIComponents/Input/Email/DAIEmailInput'
import DAIPasswordInput from 'src/DAIComponents/Input/Password/DAIPasswordInput'
import useAuthContext from 'src/hooks/useAuthContext'
import { AppRoutes } from 'src/utils/constants'

const loginSchema = z.object({
  email: z.string().email('Invalid email format'),
  password: z.string().min(1, 'Password is required'),
  rememberMe: z.boolean().optional(),
})

export default function Login() {
  const navigate = useNavigate()
  const { isLoading, loginMutation } = useAuthContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginType>({
    resolver: zodResolver(loginSchema),
  })

  const onForgotPassword = useCallback(() => {
    navigate(AppRoutes.forgotPassword)
  }, [])

  const loginSubmit = useCallback(
    (data: LoginType) => {
      loginMutation.mutate(data)
    },
    [loginMutation]
  )

  if (isLoading || loginMutation.isPending) {
    return <Loading full={true} />
  }

  return (
    <>
      <div className="flex min-h-screen flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-14 w-auto"
                src={'/images/logo.svg'}
                alt="DeliverAI"
              />
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Not a member?{' '}
                <a
                  href="https://deliverai.io/get-a-demo/"
                  className="font-semibold text-blue-600 hover:text-blue-500"
                >
                  Book a demo
                </a>
              </p>
            </div>

            <div className="mt-10">
              <form onSubmit={handleSubmit(loginSubmit)} className="space-y-6">
                <DAIEmailInput
                  name="email"
                  label="Email address"
                  register={register('email')}
                  error={errors.email?.message}
                  required
                />
                <DAIPasswordInput
                  name="password"
                  label="Password"
                  register={register('password')}
                  error={errors.password?.message}
                  required
                  showStrengthMeter={false}
                />
                <div className="flex items-center justify-between">
                  <DAICheckBox
                    name="rememberMe"
                    label="Remember me"
                    register={register('rememberMe')}
                  />
                  <div className="text-sm leading-6">
                    <a
                      onClick={onForgotPassword}
                      className="font-semibold text-blue-600 hover:text-blue-500 cursor-pointer"
                    >
                      Forgot password?
                    </a>
                  </div>
                </div>
                <DAIButton buttonType="submit" text="Sign in" />
              </form>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <div className="image-container">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="/images/Jewelry_Store.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
