import React from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';

type DAICheckBoxProps = Omit<DAIInputComponentBase, 'type' | 'validation'>;

export default function DAICheckBox({
  name,
  label,
  register,
  required,
  error,
  disabled,
  className = '',
  flexDirection = 'row',
  onChange,
}: DAICheckBoxProps) {
  const baseClasses = "h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600";
  const disabledClasses = disabled ? "opacity-50 cursor-not-allowed" : "";

  return (
    <div className={`flex items-center ${className}`}>
      <input
        id={name}
        type="checkbox"
        {...register}
        required={required}
        disabled={disabled}
        onChange={(e) => onChange?.(e.target.checked)}
        className={`${baseClasses} ${disabledClasses}`}
      />
      {label && (
        <label
          htmlFor={name}
          className={`ml-3 block text-sm leading-6 text-gray-700 ${
            disabled ? 'opacity-50' : ''
          }`}
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      )}
      {error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}
