import React, { createContext, PropsWithChildren, useCallback, useState, useMemo } from 'react'
import { Person } from '../types/Person'
import { useNavigate } from 'react-router-dom'
import { useUsermaven } from '@usermaven/react'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from '@tanstack/react-query'
import { useNotifications } from './notificationContext'
import { DAIRequest } from '../utils/DAIRequest'
import { AppRoutes } from '../utils/constants'

const ITEMS_PER_PAGE = 50;

type ContactContextType = {
  loading: boolean
  people: Person[]
  onNameClick: (id: string) => void
  createContact: (data: Person) => Promise<void>
  editContact: (data: Person) => Promise<void>
  deleteContact: (id: string) => Promise<void>
  fetchMoreContacts: () => void
  hasMore: boolean
}

const ContactContext = createContext<ContactContextType | null>(null)

export const ContactContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()
  const queryClient = useQueryClient()

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['contacts', searchQuery],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await DAIRequest('GET', "/api/v1/contacts/", {
          query: {
            page: pageParam,
            limit: ITEMS_PER_PAGE,
            sort: 'createdAt:desc',
            ...(searchQuery ? { search: searchQuery } : {}),
          },
        })
        return {
          contacts: response.data,
          nextPage: response.data.length === ITEMS_PER_PAGE ? pageParam + 1 : undefined,
        }
      } catch (error: any) {
        addNotification('error', error.message || 'Failed to fetch contacts')
        throw error
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialPageParam: 1,
  })

  const people = useMemo(() => {
    return data?.pages.flatMap(page => page.contacts) ?? [];
  }, [data]);

  const onNameClick = useCallback(
    (id: string) => {
      navigate(AppRoutes.contactProfile.replace(':id', id))
    },
    [navigate]
  )

  const createContactMutation = useMutation<Person, Error, Person>({
    mutationFn: async (newContact: Person) => {
      try {
        const response = await DAIRequest('POST', '/api/v1/contacts/createContact', {
          body: newContact,
        })
        return response.data
      } catch (error: any) {
        throw new Error(error.message || 'Failed to create contact')
      }
    },
    onSuccess: async () => {
      await track('Contact_Created')
      addNotification('success', 'Contact Created')
      queryClient.invalidateQueries({ queryKey: ['contacts'] })
    },
    onError: async (error: Error) => {
      await track('Contact_Create_Error')
      addNotification('error', error.message)
    },
  })

  const editContactMutation = useMutation<Person, Error, Person>({
    mutationFn: async (updatedContact: Person) => {
      try {
        const response = await DAIRequest('PATCH', '/api/v1/contacts/updateContact/:id', {
          params: { id: updatedContact.id },
          body: updatedContact,
        })
        return response.data
      } catch (error: any) {
        throw new Error(error.message || 'Failed to update contact')
      }
    },
    onSuccess: async () => {
      await track('Contact_Edited')
      addNotification('success', 'Contact Edited')
      queryClient.invalidateQueries({ queryKey: ['contacts'] })
    },
    onError: async (error: Error) => {
      await track('Contact_Edit_Error')
      addNotification('error', error.message)
    },
  })

  const deleteContactMutation = useMutation<void, Error, string>({
    mutationFn: async (id: string) => {
      try {
        await DAIRequest('DELETE', '/api/v1/contacts/:id', {
          params: { id },
        })
      } catch (error: any) {
        throw new Error(error.message || 'Failed to delete contact')
      }
    },
    onSuccess: async () => {
      await track('Contact_Deleted')
      addNotification('success', 'Contact Deleted')
      queryClient.invalidateQueries({ queryKey: ['contacts'] })
    },
    onError: async (error: Error) => {
      await track('Contact_Delete_Error')
      addNotification('error', error.message)
    },
  })

  const editContact = useCallback(
    async (data: Person) => {
      await editContactMutation.mutateAsync(data)
    },
    [editContactMutation]
  )

  const createContact = useCallback(
    async (data: Person) => {
      await createContactMutation.mutateAsync(data)
    },
    [createContactMutation]
  )

  const deleteContact = useCallback(
    async (id: string) => {
      await deleteContactMutation.mutateAsync(id)
    },
    [deleteContactMutation]
  )

  const fetchMoreContacts = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  return (
    <ContactContext.Provider
      value={{
        loading: isLoading || isFetchingNextPage,
        people,
        onNameClick,
        createContact,
        editContact,
        deleteContact,
        fetchMoreContacts,
        hasMore: !!hasNextPage,
      }}
    >
      {children}
    </ContactContext.Provider>
  )
}

export default ContactContext
