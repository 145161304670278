import React from 'react'
import { useQuery } from '@tanstack/react-query'
import Loading from 'src/DAIComponents/Common/Loading'
import { DAIRequest } from 'src/utils/DAIRequest'

type WebsiteInfo = {
  miniExtId: string
}

export default function WebsiteBuilder() {
  const { data, isLoading } = useQuery<WebsiteInfo>({
    queryKey: ['getMiniExtId'],
    queryFn: async () => {
      return (await DAIRequest('get', '/api/v1/website/getMiniExtId')).data
    },
  })

  if (isLoading) {
    return <Loading full={true} />
  }
  return (
    <>
      <iframe
        id={`miniExtIframe-${data?.miniExtId}`}
        height="800"
        src={`https://web.miniextensions.com/${data?.miniExtId}`}
      ></iframe>
      <script
        id="embed-script-id"
        type="text/javascript"
        src={`https://web.miniextensions.com/statics/embed.js?miniExtIframeId=miniExtIframe-${data?.miniExtId}`}
      ></script>
    </>
  )
}
