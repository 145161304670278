import { useCallback } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Person } from '../../types/Person'
import useContactContext from '../../hooks/useContactContext'
import { ModalProps } from '../../types/ModalProps'
import { formatISOToDate } from '../../utils/timezone'
import DAITextBox from '../../DAIComponents/Input/Text/DAITextBox'
import DAIEmailInput from '../../DAIComponents/Input/Email/DAIEmailInput'
import DAIPhoneInput from '../../DAIComponents/Input/Phone/DAIPhoneInput'
import DAIDateInput from '../../DAIComponents/Input/Date/DAIDateInput'
import DAIButton from '../../DAIComponents/Input/Button/DAIButton'
import DAIModal from '../../DAIComponents/Modal/DAIModal'
import { z } from 'zod'

const contactSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email format'),
  phoneNumber: z.string()
    .min(10, 'Phone number must be at least 10 digits')
    .regex(/^\+?[\d\s-()]+$/, 'Invalid phone number format'),
  birthday: z.string().optional(),
  anniversary: z.string().optional(),
})

type ContactForm = z.infer<typeof contactSchema>

export default function CreateContactModal(props: ModalProps<Person>) {
  const { createContact, editContact } = useContactContext()

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<ContactForm>({
    resolver: zodResolver(contactSchema),
    defaultValues: {
      ...props.initialData,
      anniversary: formatISOToDate(props.initialData?.anniversary),
      birthday: formatISOToDate(props.initialData?.birthday),
    },
  })

  const onSubmit = useCallback(
    async (data: ContactForm) => {
      const formattedData = {
        ...data,
        id: props.initialData?.id ?? '',
        recentSale: props.initialData?.recentSale ?? '',
        anniversary: data.anniversary ? new Date(data.anniversary).toISOString() : null,
        birthday: data.birthday ? new Date(data.birthday).toISOString() : null,
      } as Person;

      if (props.initialData) {
        await editContact(formattedData)
      } else {
        const { id, recentSale, ...createData } = formattedData;
        await createContact(createData as Person)
      }
      props.closeModal()
    },
    [createContact, editContact, props.closeModal, props.initialData]
  )

  return (
    <DAIModal isOpen={props.isOpen} onClose={props.closeModal}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Contact Information
            </h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <DAITextBox
                  name="firstName"
                  label="First name"
                  register={register('firstName')}
                  error={errors.firstName?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAITextBox
                  name="lastName"
                  label="Last name"
                  register={register('lastName')}
                  error={errors.lastName?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAIEmailInput
                  name="email"
                  label="Email address"
                  register={register('email')}
                  error={errors.email?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAIPhoneInput
                  name="phoneNumber"
                  label="Phone Number"
                  register={register('phoneNumber')}
                  error={errors.phoneNumber?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAIDateInput
                  name="birthday"
                  label="Birthday"
                  register={register('birthday')}
                  error={errors.birthday?.message}
                />
              </div>

              <div className="sm:col-span-3">
                <DAIDateInput
                  name="anniversary"
                  label="Anniversary"
                  register={register('anniversary')}
                  error={errors.anniversary?.message}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <DAIButton
            buttonType="button"
            text="Cancel"
            variant="secondary"
            onClick={props.closeModal}
          />
          <DAIButton
            buttonType="submit"
            text="Save"
            variant="primary"
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </DAIModal>
  )
}
