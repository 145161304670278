import { useCallback } from 'react'
import { Sale } from '../../types/Sale'
import { ModalProps } from '../../types/ModalProps'
import useProfileContext from '../../hooks/useProfileContext'
import { useParams } from 'react-router-dom'
import CreateSaleModal from '../../pages/Sales/CreateSaleModal'
import useSaleContext from '../../hooks/useSaleContext'

export default function CreateProfileSaleModal(props: ModalProps<Sale>) {
  const { id } = useParams()
  const { createSale, editSale, contacts, products } = useProfileContext()

  const onSubmit = useCallback(
    async (data: Sale) => {
      if (props.initialData) {
        const updateData = {
          ...data,
          contactId: id!,
        }
        await editSale(updateData)
      } else {
        const createData = {
          ...data,
          contactId: id!,
        }
        await createSale(createData)
      }
    },
    [createSale, editSale, id]
  )

  return (
      <CreateSaleModal
        isOpen={true}
        closeModal={props.closeModal}
        initialData={props.initialData}
        onSubmit={onSubmit}
        openModal={props.openModal}
    />
  )
}
