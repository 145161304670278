import React from 'react';
import DAITextBox from '../Text/DAITextBox';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import { emailSchema } from '../../Base/validation';

type DAIEmailInputProps = Omit<DAIInputComponentBase, 'type'>;

export default function DAIEmailInput(props: DAIEmailInputProps) {
  return (
    <DAITextBox
      {...props}
      type="email"
      validation={{
        required: props.required,
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        customError: 'Please enter a valid email address'
      }}
    />
  );
} 