import React from 'react'
import UnipileIntegrationSection from './Sections/UnipileIntegrationSection'

export default function IntegrationSettings() {
  return (
    <div>
      {/* Settings forms */}
      <div className="divide-y divide-white/5">
        <UnipileIntegrationSection />
      </div>
    </div>
  )
}
