import React from 'react'
import { AutomationContextProvider } from 'src/contexts/automationContext'
import AutomationTable from './AutomationTable'

export default function Automations() {
  return (
    <AutomationContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <AutomationTable />
      </div>
    </AutomationContextProvider>
  )
}
