import { ProductContextProvider } from "src/contexts/productContext";
import ProductsTable from "./ProductsTable";

export default function Products() {
  return (
    <ProductContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <ProductsTable />
      </div>
    </ProductContextProvider>
  )
}
