import { createContext, PropsWithChildren, useCallback, useMemo } from 'react'
import { Sale } from '../types/Sale'
import { useUsermaven } from '@usermaven/react'
import { PersonSimple } from '../types/Person'
import { ProductSimple } from '../types/Product'
import useSearchContext from '../hooks/useSearchContext'
import { useMutation, useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { DAIRequest } from '../utils/DAIRequest'
import { useNotifications } from './notificationContext'
import { useNavigate } from 'react-router-dom'

const ITEMS_PER_PAGE = 50;

type SaleContextType = {
  loading: boolean
  sales: Sale[]
  createSale: (data: Sale) => void
  editSale: (data: Sale) => void
  contacts: PersonSimple[]
  products: ProductSimple[]
  onNameClick: (id: string) => void
  deleteSale: (id: string) => void
  fetchMoreSales: () => void
  hasMore: boolean
}

const SaleContext = createContext<SaleContextType | undefined>(undefined)

export const SaleContextProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { track } = useUsermaven()
  const { searchQuery } = useSearchContext()
  const { addNotification } = useNotifications()
  const queryClient = useQueryClient()

  const onNameClick = useCallback(
    (id: string) => {
      navigate(`/dashboard/contacts/profile/${id}`)
    },
    [navigate]
  )

  const {
    data,
    isLoading: salesLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['sales', searchQuery],
    queryFn: async ({ pageParam = 1 }) => {
      try {
        const response = await DAIRequest('GET', '/api/v1/purchases/getAllMyPurchases', {
          query: {
            page: pageParam,
            limit: ITEMS_PER_PAGE,
            sort: 'createdAt:desc',
            ...(searchQuery ? { search: searchQuery } : {}),
          },
        });
        return {
          sales: response.data,
          nextPage: response.data.length === ITEMS_PER_PAGE ? pageParam + 1 : undefined,
        };
      } catch (error: any) {
        addNotification('error', error.message || 'Failed to fetch sales');
        throw error;
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
    initialPageParam: 1,
  });

  const sales = useMemo(() => {
    return data?.pages.flatMap(page => page.sales) ?? [];
  }, [data]);

  const fetchMoreSales = useCallback(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const { data: contacts, isLoading: contactsLoading } = useQuery<
    PersonSimple[]
  >({
    queryKey: ['contact', 'basic'],
    queryFn: async () => {
      return (await DAIRequest('get', '/api/v1/contacts/basic')).data
    },
  })

  const { data: products, isLoading: productsLoading } = useQuery<
    ProductSimple[]
  >({
    queryKey: ['product-categories', 'basic'],
    queryFn: async () => {
      return (
        await DAIRequest(
          'get',
          '/api/v1/product-categories/getMyProductCategoriesBasic'
        )
      ).data
    },
  })

  const createSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('post', '/api/v1/purchases/createPurchase', {
        body: data,
      })
    },
    onSuccess: async () => {
      await track('Sale_Created')
      queryClient.invalidateQueries({ queryKey: ['sales'] })
      addNotification('success', 'Sale created successfully')
    },
    onError: () => {
      addNotification('error', 'Error creating sale')
    },
  })

  const editSale = useMutation({
    mutationFn: async (data: Sale) => {
      return await DAIRequest('patch', '/api/v1/purchases/updatePurchase/:id', {
        body: data,
        params: { id: data.id },
      })
    },
    onSuccess: async () => {
      await track('Sale_Edited')
      queryClient.invalidateQueries({ queryKey: ['sales'] })
      addNotification('success', 'Sale edited successfully')
    },
    onError: async () => {
      await track('Sale_Edited_Error')
      addNotification('error', 'Error editing sale')
    },
  })

  const deleteSale = useMutation({
    mutationFn: async (id: string) => {
      return await DAIRequest('delete', '/api/v1/purchases/:id', {
        params: { id },
      })
    },
    onSuccess: async () => {
      await track('Sale_Deleted')
      queryClient.invalidateQueries({ queryKey: ['sales'] })
      addNotification('success', 'Sale deleted successfully')
    },
    onError: async () => {
      await track('Sale_Deleted_Error')
      addNotification('error', 'Error deleting sale')
    },
  })

  const loading = useMemo(() => {
    return salesLoading || contactsLoading || productsLoading || isFetchingNextPage
  }, [salesLoading, contactsLoading, productsLoading, isFetchingNextPage])

  return (
    <SaleContext.Provider
      value={{
        loading,
        createSale: createSale.mutate,
        editSale: editSale.mutate,
        sales,
        contacts: contacts ?? [],
        products: products ?? [],
        deleteSale: deleteSale.mutate,
        onNameClick,
        fetchMoreSales,
        hasMore: !!hasNextPage,
      }}
    >
      {children}
    </SaleContext.Provider>
  )
}

export default SaleContext
