import React, { useState } from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import DAILabel from '../../Common/DAILabel';


type DAIPasswordInputProps = Omit<DAIInputComponentBase, 'type'> & {
  showStrengthMeter?: boolean;
};

export default function DAIPasswordInput({
  showStrengthMeter = false,
  ...props
}: DAIPasswordInputProps) {
  const [password, setPassword] = useState('');

  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = props.error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = props.disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    props.onChange?.(e);
  };

  const getPasswordStrength = (password: string): number => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/[0-9]/.test(password)) strength++;
    if (/[^A-Za-z0-9]/.test(password)) strength++;
    return strength;
  };

  const strengthColors = [
    'bg-red-500',
    'bg-orange-500',
    'bg-yellow-500',
    'bg-blue-500',
    'bg-green-500',
  ];

  return (
    <div className={`flex flex-${props.flexDirection || 'col'} ${props.className || ''}`}>
      {props.label && <DAILabel name={props.label} required={props.required} />}
      <div className={props.flexDirection === 'col' ? 'mt-2' : ''}>
        <div className="relative">
          <input
            {...props.register}
            onChange={handleChange}
            type="password"
            className={`${baseClasses} ${errorClasses} ${disabledClasses}`}
          />
          {showStrengthMeter && (
            <div className="mt-2 space-y-2">
              <div className="flex gap-2">
              {strengthColors.map((color, index) => (
                <div
                  key={index}
                  className={`h-1 flex-1 rounded-full ${
                    index < getPasswordStrength(password)
                      ? color
                      : 'bg-gray-200'
                  }`}
                />
              ))}
            </div>
            <p className="mt-1 text-xs text-gray-500">
              Password strength: {['Very Weak', 'Weak', 'Fair', 'Good', 'Strong'][
                getPasswordStrength(password) - 1
              ] || 'Very Weak'}
              </p>
            </div>
          )}
        </div>
        {props.error && (
          <p className="mt-2 text-sm text-red-600">{props.error}</p>
        )}
      </div>
    </div>
  );
} 