import { DOMAttributes, FormEventHandler, PropsWithChildren } from 'react'
import { FieldValues } from 'react-hook-form'

interface DAIFormProps<T extends FieldValues> extends PropsWithChildren {
  onSubmit: FormEventHandler<HTMLFormElement>
  className?: string
}

export function DAIForm<T extends FieldValues>({
  onSubmit,
  children,
  className,
}: DAIFormProps<T>) {
  return (
    <form 
      onSubmit={onSubmit} 
      autoComplete="off"
      className={className}
      noValidate={true}
    >
      {children}
    </form>
  )
} 