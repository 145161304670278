import { DialogTitle } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ModalProps } from '../../types/ModalProps'
import { useMutation } from '@tanstack/react-query'
import { DAIRequest } from '../../utils/DAIRequest'
import { useNotifications } from '../../contexts/notificationContext'
import { useCallback } from 'react'
import DAIModal from '../../DAIComponents/Modal/DAIModal'

export default function RemoveUserModal(props: ModalProps<string>) {
  const { addNotification } = useNotifications()

  const removeUserMutation = useMutation({
    mutationFn: async (id: string) => {
      await DAIRequest('post', '/api/v1/authentication/removeUser', {
        body: { id },
      })
    },
    onSuccess: () => {
      addNotification('success', 'User removed successfully')
      props.closeModal()
    },
    onError: () => {
      addNotification('error', 'Failed to remove user')
    },
  })

  const onDelete = useCallback(() => {
    if (!props.initialData) {
      return
    }
    removeUserMutation.mutate(props.initialData)
  }, [removeUserMutation])

  return (
    <DAIModal isOpen={props.isOpen} onClose={props.closeModal} maxWidth="lg">
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <DialogTitle
            as="h3"
            className="text-base font-semibold leading-6 text-gray-900"
          >
            Remove User
          </DialogTitle>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Are you sure you want to remove this user? All of their data
              will be permanently removed.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          onClick={onDelete}
        >
          Deactivate
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={props.closeModal}
        >
          Cancel
        </button>
      </div>
    </DAIModal>
  )
}
