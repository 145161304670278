import React, { useState } from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import DAILabel from '../../Common/DAILabel';
import { phoneSchema } from '../../Base/validation';

type DAIPhoneInputProps = Omit<DAIInputComponentBase, 'type'> & {
  defaultCountryCode?: string;
};

const countryCodes = [
  { code: '+1', country: 'US/CA' },
  { code: '+44', country: 'UK' },
  { code: '+91', country: 'IN' },
  { code: '+61', country: 'AU' },
  // Add more country codes as needed
];

export default function DAIPhoneInput({
  name,
  label,
  register,
  required,
  error,
  disabled,
  placeholder = 'Enter phone number',
  defaultCountryCode = '+1',
  className = '',
  flexDirection = 'col',
  onChange,
}: DAIPhoneInputProps) {
  const [countryCode, setCountryCode] = useState(defaultCountryCode);

  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // Remove all non-numeric characters except + and spaces
    const formattedValue = value.replace(/[^\d\s+]/g, '');
    e.target.value = formattedValue;
    onChange?.(countryCode + ' ' + formattedValue);
  };

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={`flex ${flexDirection === 'col' ? 'mt-2' : ''}`}>
        <select
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          disabled={disabled}
          className={`rounded-l-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm ${disabledClasses}`}
        >
          {countryCodes.map((country) => (
            <option key={country.code} value={country.code}>
              {country.code} {country.country}
            </option>
          ))}
        </select>
        <input
          id={name}
          type="tel"
          {...register}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handlePhoneChange}
          className={`${baseClasses} ${errorClasses} ${disabledClasses} rounded-l-none flex-1`}
        />
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
} 