import { Dispatch, SetStateAction } from 'react'
import { useForm } from 'react-hook-form'
import { Note } from '../../types/Note'
import DAITextArea from '../../DAIComponents/Input/TextArea/DAITextArea'
import useProfileContext from '../../hooks/useProfileContext'
import { CreateNote, UpdateNote } from '../../contexts/profileContext'
import { useParams } from 'react-router-dom'
import DAIModal from '../../DAIComponents/Modal/DAIModal'
import DAIButton from 'src/DAIComponents/Input/Button/DAIButton'
import { DAIForm } from 'src/DAIComponents/Form/DAIForm'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const noteSchema = z.object({
  content: z.string().min(1, 'Note content is required'),
})

type NoteFormData = z.infer<typeof noteSchema>

export default function CreateNotesModal(props: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  initialData?: Note
  onSubmit: () => void
}) {
  const { id } = useParams()
  const { createNoteMutation, updateNoteMutation } = useProfileContext()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NoteFormData>({
    resolver: zodResolver(noteSchema),
    defaultValues: props.initialData,
  })

  const onSubmit = (data: NoteFormData) => {
    if (props.initialData) {
      const updateNote: UpdateNote = {
        id: props.initialData?.id!,
        content: data.content,
        contactId: id!,
      }
      updateNoteMutation.mutate(updateNote)
    } else {
      const createData: CreateNote = {
        content: data.content,
        contactId: id!,
      }
      createNoteMutation.mutate(createData)
    }

    props.setOpen(false)
  }

  return (
    <DAIModal isOpen={props.open} onClose={() => props.setOpen(false)}>
      <DAIForm onSubmit={handleSubmit(onSubmit)}>
        <DAITextArea
          name="content"
          register={register('content')}
          autoResize={true}
          minRows={10}
          required
          error={errors.content?.message}
        />
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <DAIButton
            buttonType="button"
            text="Cancel"
            variant="secondary"
            onClick={() => props.setOpen(false)}
          />
          <DAIButton
            buttonType="submit"
            text="Save"
            variant="primary"
            isLoading={updateNoteMutation.isPending || createNoteMutation.isPending}
          />
        </div>
      </DAIForm>
    </DAIModal>
  )
}
