import { ModalProps } from '../../types/ModalProps'
import DAIModal from '../../DAIComponents/Modal/DAIModal'

export default function UseAIModal(props: ModalProps<any>) {
  return (
    <DAIModal isOpen={props.isOpen} onClose={props.closeModal} showCloseButton>
      <iframe
        src="https://us.frms.link/4zejkdf"
        style={{
          width: '100%',
          height: '500px',
          maxHeight: '100%',
          maxWidth: '100%',
          backgroundColor: 'transparent',
        }}
        frameBorder="none"
        scrolling="no"
      ></iframe>
    </DAIModal>
  )
}
