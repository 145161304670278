import React, { useCallback, useState } from 'react'
import { Person } from '../../types/Person'
import useContactContext from '../../hooks/useContactContext'
import { Column, Table, TableButton } from '../../DAIComponents/Table/DAITable'
import CreateContactModal from './CreateContactModal'
import RemoveModal from 'src/DAIComponents/Modal/RemoveModal'

const ITEMS_PER_PAGE = 50;

const ContactTable = () => {
  const [modelOpen, setModelOpen] = useState<boolean>(false)
  const [initialData, setInitialData] = useState<Person>()
  const { loading, people, onNameClick, deleteContact, fetchMoreContacts, hasMore } = useContactContext()
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [id, setId] = useState<string>('')

  const onEdit = useCallback(
    (id: string) => {
      const person = people.find(p => p.id === id)
      setInitialData(person)
      setModelOpen(true)
    },
    [modelOpen, people, setModelOpen, setInitialData]
  )

  const onClose = useCallback(() => {
    setModelOpen(false)
    setInitialData(undefined)
  }, [setModelOpen, setInitialData])

  const openModal = useCallback(() => {
    setModelOpen(true)
  }, [modelOpen, setModelOpen])

  const onDelete = useCallback(
    (id: string) => {
      setRemoveModal(true)
      setId(id)
    },
    [setId, setRemoveModal]
  )

  return (
    <>
      {modelOpen && (
        <CreateContactModal
          isOpen={modelOpen}
          closeModal={onClose}
          initialData={initialData}
          openModal={openModal}
        />
      )}
      {removeModal && (
        <RemoveModal
          isOpen={removeModal}
          closeModal={() => {
            setRemoveModal(false)
            setId('')
          }}
          openModal={() => setRemoveModal(true)}
          onDelete={() => {
            deleteContact(id)
            setRemoveModal(false)
            setId('')
          }}
          title={'Delete Contact'}
          text={
            'Are you sure you want to remove this contact? ' +
            'All of the data associated with this contact will be permanently removed.'
          }
        />
      )}
      <Table
        loading={loading}
        onAdd={openModal}
        data={people}
        title={'Contacts'}
        description={
          'A list of all the contacts in your account including their name, title, email and role.'
        }
        onLoadMore={fetchMoreContacts}
        hasMore={hasMore}
        maxHeight="70vh"
      >
        <Column title="Name">
          {(person: Person) => (
            <TableButton
              onClick={() => onNameClick(person.id)}
              srValue={person.firstName + ' ' + person.lastName}
              title={person.firstName + ' ' + person.lastName}
            />
          )}
        </Column>
        <Column title="Phone Number">
          {(person: Person) => person.phoneNumber}
        </Column>
        <Column title="Email">{(person: Person) => person.email}</Column>
        <Column title="Recent Sale">
          {(person: Person) => person.recentSale}
        </Column>
        <Column title="">
          {(person: Person) => (
            <TableButton
              editButton={true}
              onClick={() => onEdit(person.id)}
              srValue={person.firstName + ' ' + person.lastName}
            />
          )}
        </Column>
        <Column title="">
          {(person: Person) => (
            <TableButton
              deleteButton={true}
              onClick={() => onDelete(person.id)}
              srValue={person.firstName + ' ' + person.lastName}
            />
          )}
        </Column>
      </Table>
    </>
  )
}

export default ContactTable
