import React, { useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { ProfileData } from 'src/contexts/profileContext'
import DAIDateInput from 'src/DAIComponents/Input/Date/DAIDateInput'
import DAIEmailInput from 'src/DAIComponents/Input/Email/DAIEmailInput'
import DAINameInput from 'src/DAIComponents/Input/Name/DAINameInput'
import DAIPhoneInput from 'src/DAIComponents/Input/Phone/DAIPhoneInput'
import useProfileContext from 'src/hooks/useProfileContext'
import { formatDateForInput, convertToLocaleDate } from 'src/utils/timezone'

const ProfileForm = () => {
  const {
    isEditing,
    setIsEditing,
    updateProfileMutation,
    profileData: data,
  } = useProfileContext()
  const { register, setValue, handleSubmit } = useForm<ProfileData>()

  const handleSubmitForm = (data: ProfileData) => {
    updateProfileMutation.mutate(data)
  }

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleCancel = useCallback(() => {
    setIsEditing(false)
  }, [])

  useEffect(() => {
    if (data) {
      setValue('firstName', data.firstName)
      setValue('lastName', data.lastName)
      setValue('email', data.email)
      setValue('phoneNumber', data.phoneNumber)
      setValue('anniversary', formatDateForInput(data.anniversary))
      setValue('birthday', formatDateForInput(data.birthday))
    }
  }, [data])

  return (
    <form onSubmit={handleSubmit(handleSubmitForm)}>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-bold">Profile</h1>
          <p className="mt-2 text-sm text-gray-700">
            Information about your contact.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          {isEditing && (
            <div className="flex flex-row space-x-2">
              <button
                type="submit"
                className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Save
              </button>
              <button
                type="button"
                className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          )}
          {!isEditing && (
            <button
              type="button"
              className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={handleEdit}
            >
              Edit
            </button>
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="pb-2.5">
          {isEditing ? (
            <DAINameInput
              name="firstName"
              register={register('firstName')}
              placeholder="First Name"
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              First Name: {data?.firstName}
            </p>
          )}
        </div>
        <div className="pb-2.5">
          {isEditing ? (
            <DAINameInput
              name="lastName"
              register={register('lastName')}
              placeholder="Last Name"
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              Last Name: {data?.lastName}
            </p>
          )}
        </div>
        <div className="pb-2.5">
          {isEditing ? (
            <DAIEmailInput
              name="email"
              register={register('email')}
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              Email: {data?.email}
            </p>
          )}
        </div>
        <div className="pb-2.5">
          {isEditing ? (
            <DAIPhoneInput
              name="phoneNumber"
              register={register('phoneNumber')}
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              Phone Number: {data?.phoneNumber}
            </p>
          )}
        </div>
        <div className="pb-2.5">
          {isEditing ? (
            <DAIDateInput
              name="birthday"
              register={register('birthday')}
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              Birthday: {convertToLocaleDate(data?.birthday)}
            </p>
          )}
        </div>
        <div className="pb-2.5">
          {isEditing ? (
            <DAIDateInput
              name="anniversary"
              register={register('anniversary')}
            />
          ) : (
            <p className="p-2.5 rounded-md shadow-sm text-gray-900">
              Anniversary: {convertToLocaleDate(data?.anniversary)}
            </p>
          )}
        </div>
      </div>
    </form>
  )
}

export default ProfileForm
