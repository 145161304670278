import axios, { Method, AxiosError } from 'axios'
import { API_URL } from './constants'

interface APIError {
  status: number;
  message: string;
  stack?: string;
}

export async function DAIRequest(
  method: Method,
  endpoint: AllowedEndpoints,
  options: RequestArgs = {}
) {
  const headers = {
    ...options.headers,
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }

  // Generate the full URL with any necessary parameter substitutions.
  let url = generateUrl(endpoint, options.params)

  // Add query parameters if they exist
  if (options.query) {
    const queryParams = new URLSearchParams()
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== undefined) {
        queryParams.append(key, value.toString())
      }
    })
    const queryString = queryParams.toString()
    if (queryString) {
      url += `?${queryString}`
    }
  }

  try {
    const response = await axios({
      method,
      url,
      headers: headers,
      data: options.body,
    })
    return response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const apiError = error.response?.data as APIError
      throw {
        status: error.response?.status || 500,
        message: apiError?.message || 'An unexpected error occurred',
        originalError: error
      }
    }
    throw {
      status: 500,
      message: 'An unexpected error occurred',
      originalError: error
    }
  }
}

// List of allowed API endpoints to ensure valid endpoint usage in requests
const allowedEndpoints = [
  // Authentication
  '/api/v1/authentication/login',
  '/api/v1/authentication/createInvitedUser',
  '/api/v1/authentication/inviteUser',
  '/api/v1/authentication/removeUser',

  // User Routes
  '/api/v1/users/',
  '/api/v1/users/me',
  '/api/v1/users/updateUser',
  '/api/v1/users/getUserById/:id',
  '/api/v1/users/changePassword',

  // Contact Routes
  '/api/v1/contacts/',
  '/api/v1/contacts/basic',
  '/api/v1/contacts/:id',
  '/api/v1/contacts/updateContact/:id',
  '/api/v1/contacts/createContact',

  // Organization Routes
  '/api/v1/organization/changeTimezone',
  '/api/v1/organization/users',

  // Extra Date Routes
  '/api/v1/extra-dates/:id',
  '/api/v1/extra-dates/',
  '/api/v1/extra-dates/:id',
  '/api/v1/extra-dates/contact/:id',

  // Template Routes
  '/api/v1/templates/getMyTemplates',
  '/api/v1/templates/createTemplate',
  '/api/v1/templates/:id',
  '/api/v1/templates/updateTemplate/:id',
  '/api/v1/templates/deleteTemplate/:id',
  '/api/v1/templates/placeholders',

  // Purchase Routes
  '/api/v1/purchases/getAllMyPurchases',
  '/api/v1/purchases/createPurchase',
  '/api/v1/purchases/updatePurchase/:id',
  '/api/v1/purchases/getAllPurchasesByContactId/:id',
  '/api/v1/purchases/:id',

  // Product Category Routes
  '/api/v1/product-categories/getMyProductCategories',
  '/api/v1/product-categories/createProductCategory',
  '/api/v1/product-categories/updateProductCategory/:id',
  '/api/v1/product-categories/getMyProductCategoriesBasic',
  '/api/v1/product-categories/:id',

  // Dashboard routes
  '/api/v1/dashboard/',

  // Automation routes
  '/api/v1/automations/getAutomations',

  // Website Routes
  '/api/v1/website/getMiniExtId',

  // Note Routes
  '/api/v1/notes/contact/:id',
  '/api/v1/notes/:id',
  '/api/v1/notes/',

  '/api/v1/triggers/getTriggers',

  // UniPile Routes
  '/api/v1/unipile/generateAccountLink',
  '/api/v1/unipile/getAccountInfo',
  '/api/v1/unipile/removeAccount',
] as const

// Type alias for allowed endpoints to restrict function parameters to valid endpoints
export type AllowedEndpoints = (typeof allowedEndpoints)[number]

// Interface for common request parameters
type RequestArgs = {
  headers?: Record<string, string>
  body?: any
  params?: Record<string, string | number>
  query?: Record<string, string | number | undefined>
}

export type IdRequiredEndPoints =
  | '/api/v1/templates/:id'
  | '/api/v1/contacts/:id'

export type IdAndBodyRequiredEndPoints = ''

export type IdRequiredEndpointOptions = Omit<RequestArgs, 'body' | 'params'> & {
  params: { id: string }
}

export type IdAndBodyRequiredEndpointOptions = Omit<RequestArgs, 'params'> & {
  body: any
  params: { id: string }
}

export type NonIdRequiredEndPoints = Exclude<
  AllowedEndpoints,
  IdRequiredEndPoints
>

/**
 * Generates a complete URL by appending the endpoint to the BACKEND_URL and
 * interpolating any parameters into the endpoint string.
 *
 * @param endpoint - The API endpoint to call.
 * @param params - Optional parameters to replace in the endpoint string.
 * @return The fully constructed URL.
 */
const generateUrl = (
  endpoint: AllowedEndpoints,
  params?: Record<string, string | number>
): string => {
  let url = `${API_URL}${endpoint}`
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      url = url.replace(`:${key}`, encodeURIComponent(value.toString()))
      // if the param has been replaced, remove it from the params object
      delete params[key]
    })
  }

  return url
}
