import React, { Fragment, useEffect, useState } from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import DAILabel from '../../Common/DAILabel';
import { Popover, Transition } from '@headlessui/react';
import { CalendarIcon } from '@heroicons/react/20/solid';
import { format, isValid, startOfMonth, endOfMonth, addMonths, subMonths } from 'date-fns';
import { classNames } from '../../../utils/className';
import { UseFormRegisterReturn } from 'react-hook-form';

type DAIDateInputProps = Omit<DAIInputComponentBase, 'type'> & {
  minDate?: string;
  maxDate?: string;
  showTime?: boolean;
  defaultValue?: string;
};

type WeekDay = Date | null;
type Week = WeekDay[];

export default function DAIDateInput({
  name,
  label,
  register,
  required,
  error,
  disabled,
  minDate,
  maxDate,
  showTime = false,
  className = '',
  flexDirection = 'col',
  defaultValue,
}: DAIDateInputProps) {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  useEffect(() => {
    if (defaultValue) {
      try {
        const date = new Date(defaultValue);
        if (isValid(date)) {
          setSelectedDate(date);
          setCurrentMonth(date);
        }
      } catch (e) {
        console.error('Invalid date format:', defaultValue);
      }
    }
  }, [defaultValue]);

  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const handleDaySelect = (date: Date) => {
    setSelectedDate(date);
    if (date && isValid(date)) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      const event = {
        target: { value: formattedDate, name },
        type: 'change',
      } as React.ChangeEvent<HTMLInputElement>;
      register?.onChange(event);
    }
  };

  const handlePrevMonth = () => {
    setCurrentMonth(prev => subMonths(prev, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth(prev => addMonths(prev, 1));
  };

  const start = startOfMonth(currentMonth);
  const end = endOfMonth(currentMonth);
  const days = Array.from({ length: end.getDate() }, (_, i) => {
    const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i + 1);
    return date;
  });

  const startOffset = start.getDay();
  const weeks: Week[] = [];
  let currentWeek: Week = [];

  // Add empty days for the start of the month
  for (let i = 0; i < startOffset; i++) {
    currentWeek.push(null);
  }

  // Add the days of the month
  days.forEach((day) => {
    if (currentWeek.length === 7) {
      weeks.push([...currentWeek]);
      currentWeek = [];
    }
    currentWeek.push(day);
  });

  // Add empty days for the end of the month
  while (currentWeek.length < 7) {
    currentWeek.push(null);
  }
  weeks.push([...currentWeek]);

  const minDateTime = minDate ? new Date(minDate) : null;
  const maxDateTime = maxDate ? new Date(maxDate) : null;

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={flexDirection === 'col' ? 'mt-2' : ''}>
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className={classNames(
                  baseClasses,
                  errorClasses,
                  disabledClasses,
                  'flex items-center justify-between pr-3'
                )}
                disabled={disabled}
              >
                <input
                  type="text"
                  className="w-full border-0 bg-transparent pl-3 p-0 placeholder:text-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Select date"
                  value={selectedDate ? format(selectedDate, 'PPP') : ''}
                  readOnly
                  {...register}
                />
                <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                show={open}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel 
                  className="absolute z-10 mt-1 transform p-4 bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                  static
                >
                  <div className="w-64">
                    <div className="flex justify-between items-center mb-4">
                      <button
                        type="button"
                        className="p-1 hover:bg-gray-100 rounded-full"
                        onClick={handlePrevMonth}
                      >
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <div className="font-semibold">
                        {format(currentMonth, 'MMMM yyyy')}
                      </div>
                      <button
                        type="button"
                        className="p-1 hover:bg-gray-100 rounded-full"
                        onClick={handleNextMonth}
                      >
                        <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                        </svg>
                      </button>
                    </div>
                    <div className="grid grid-cols-7 gap-1 text-center text-xs leading-6 text-gray-500 mb-2">
                      {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((day) => (
                        <div key={day}>{day}</div>
                      ))}
                    </div>
                    <div className="grid grid-cols-7 gap-1">
                      {weeks.map((week, weekIndex) => (
                        <React.Fragment key={weekIndex}>
                          {week.map((date, dayIndex) => {
                            if (!date) {
                              return <div key={`empty-${dayIndex}`} className="p-2" />;
                            }

                            const isSelected = selectedDate && 
                              format(selectedDate, 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
                            const isToday = format(new Date(), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd');
                            const isDisabled = (minDateTime && date < minDateTime) || 
                              (maxDateTime && date > maxDateTime);
                            
                            return (
                              <button
                                key={`${weekIndex}-${dayIndex}`}
                                type="button"
                                onClick={() => !isDisabled && handleDaySelect(date)}
                                disabled={!!isDisabled}
                                className={classNames(
                                  'p-2 text-sm rounded-full',
                                  isDisabled ? 'text-gray-300 cursor-not-allowed' : 'hover:bg-gray-100',
                                  isSelected ? 'bg-blue-600 text-white hover:bg-blue-700' : '',
                                  isToday ? 'font-bold' : '',
                                )}
                              >
                                {format(date, 'd')}
                              </button>
                            );
                          })}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
} 