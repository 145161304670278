import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import DAITextBox from '../../DAIComponents/Input/Text/DAITextBox'
import { Product } from '../../types/Product'
import { ModalProps } from '../../types/ModalProps'
import useProductContext from '../../hooks/useProductContext'
import DAIModal from '../../DAIComponents/Modal/DAIModal'
import { DAIForm } from 'src/DAIComponents/Form/DAIForm'
import DAIButton from 'src/DAIComponents/Input/Button/DAIButton'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const productSchema = z.object({
  name: z.string().min(1, 'Product name is required'),
  description: z.string().optional(),
})

type ProductFormData = z.infer<typeof productSchema>

export default function CreateProductModal(props: ModalProps<Product>) {
  const { register, handleSubmit, formState: { errors } } = useForm<ProductFormData>({
    resolver: zodResolver(productSchema),
    defaultValues: props.initialData,
  })
  const { editProduct, createProduct } = useProductContext()

  const onSubmit = useCallback(
    (data: ProductFormData) => {
      if (props.initialData) {
        const product: Product = {
          ...data,
          id: props.initialData.id,
        }
        editProduct(product)
      } else {
        createProduct(data as Product)
      }
      props.closeModal()
    },
    [createProduct, editProduct, props.closeModal]
  )

  return (
    <DAIModal isOpen={props.isOpen} onClose={props.closeModal}>
      <DAIForm onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <DAITextBox
          name="name"
          label="Product Name"
          register={register('name')}
          required={true}
          error={errors.name?.message}
        />
        <DAITextBox
          name="description"
          label="Description"
          register={register('description')}
          error={errors.description?.message}
        />
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <DAIButton
            buttonType="button"
            text="Cancel"
            variant="secondary"
            onClick={() => props.closeModal()}
          />
          <DAIButton
            buttonType="submit"
            text="Save"
            variant="primary"
          />
        </div>
      </DAIForm>
    </DAIModal>
  )
}
