import { classNames } from '../../utils/className'
import { useCallback, useState } from 'react'
import Loading from '../../DAIComponents/Common/Loading'
import { useQuery } from '@tanstack/react-query'
import useAuthContext from '../../hooks/useAuthContext'
import { TeamMember } from '../../types/Settings'
import { DAIRequest } from '../../utils/DAIRequest'
import CreateMemberModal from './CreateMemberModal'
import RemoveUserModal from './RemoveUserModal'

const TeamTable = () => {
  const [modelOpen, setModelOpen] = useState<boolean>(false)
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false)
  const [id, setId] = useState<string>('')

  const { user } = useAuthContext()

  const onClose = useCallback(async () => {
    setModelOpen(false)
    await refetch()
  }, [setModelOpen])

  const openModal = useCallback(() => {
    setModelOpen(true)
  }, [setModelOpen])

  const {
    data: team,
    isLoading,
    refetch,
  } = useQuery<TeamMember[]>({
    queryKey: ['team'],
    queryFn: async () =>
      DAIRequest('GET', '/api/v1/organization/users').then(res => res.data),
  })

  // sort team list to put you at the top
  team?.sort((a, b) => {
    if (a.id === user?.id) {
      return -1
    }
    if (b.id === user?.id) {
      return 1
    }
    return 0
  })

  const onDelete = useCallback((id: string) => {
    setId(id)
    setRemoveModalOpen(true)
  }, [])

  const closeRemoveModal = useCallback(async () => {
    setRemoveModalOpen(false)
    await refetch()
  }, [])

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loading />
      </div>
    )
  }

  return (
    <>
      {modelOpen && (
        <CreateMemberModal
          isOpen={modelOpen}
          closeModal={onClose}
          openModal={openModal}
        />
      )}
      {removeModalOpen && (
        <RemoveUserModal
          isOpen={removeModalOpen}
          closeModal={closeRemoveModal}
          openModal={() => setRemoveModalOpen(true)}
          initialData={id}
        />
      )}
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Team
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Manage your team members here.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={openModal}
          >
            Add Member
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-y-auto max-h-[550px]">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {team?.map((member, memberIdx) => (
                    <tr key={member.id}>
                      <td
                        className={classNames(
                          memberIdx !== team.length - 1
                            ? 'border-b border-gray-200'
                            : '',
                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6 lg:pl-8'
                        )}
                      >
                        {member.firstName} {member.lastName}
                      </td>
                      <td
                        className={classNames(
                          memberIdx !== team.length - 1
                            ? 'border-b border-gray-200'
                            : '',
                          'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                        )}
                      >
                        {member.email}
                      </td>
                      <td
                        className={classNames(
                          memberIdx !== team.length - 1
                            ? 'border-b border-gray-200'
                            : '',
                          'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 lg:table-cell'
                        )}
                      >
                        {member.role.name.charAt(0).toUpperCase() +
                          member.role.name.slice(1).toLowerCase()}
                      </td>
                      <td
                        className={classNames(
                          memberIdx !== team.length - 1
                            ? 'border-b border-gray-200'
                            : '',
                          'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8'
                        )}
                      >
                        {user?.id !== member.id &&
                          (user?.role === 'OWNER' ||
                            user?.role === 'ADMIN') && (
                            <button
                              onClick={() => onDelete(member.id)}
                              className="text-blue-600 hover:text-blue-900"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="size-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18 18 6M6 6l12 12"
                                />
                              </svg>
                              <span className="sr-only">
                                , {member.firstName}
                              </span>
                            </button>
                          )}
                        {user?.id === member.id && (
                          <span className="text-gray-500">You</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamTable
