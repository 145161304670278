import React from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { z } from 'zod'
import { useNotifications } from 'src/contexts/notificationContext'
import DAIButton from 'src/DAIComponents/Input/Button/DAIButton'
import DAIPasswordInput from 'src/DAIComponents/Input/Password/DAIPasswordInput'
import { AppRoutes } from 'src/utils/constants'
import { DAIRequest } from 'src/utils/DAIRequest'

const passwordSchema = z.object({
  password: z.string()
    .min(8, 'Password must be at least 8 characters')
    .regex(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .regex(/[a-z]/, 'Password must contain at least one lowercase letter')
    .regex(/[0-9]/, 'Password must contain at least one number')
    .regex(/[^A-Za-z0-9]/, 'Password must contain at least one special character'),
  confirmPassword: z.string()
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
})

type PasswordForm = z.infer<typeof passwordSchema>

export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordForm>({
    resolver: zodResolver(passwordSchema),
  })
  
  const { addNotification } = useNotifications()
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const name = searchParams.get('name')
  const token = searchParams.get('token')

  const submitMutation = useMutation({
    mutationFn: async (data: PasswordForm) => {
      await DAIRequest('post', '/api/v1/authentication/createInvitedUser', {
        body: {
          token,
          password: data.password,
        },
      })
    },
    onSuccess: () => {
      addNotification('success', 'User created successfully')
      navigate(AppRoutes.login)
    },
    onError: error => {
      addNotification('error', 'Error creating invited user')
    },
  })

  const onSubmit = async (data: PasswordForm) => {
    submitMutation.mutate(data)
  }

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-36">
          <div className={'flex flex-row justify-center'}>
            <img
              className="h-14 w-auto"
              src={'/images/logo.svg'}
              alt="DeliverAI"
            />
          </div>

          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Register
          </h2>
          <p className="text-sm text-gray-500 mt-4">
            Welcome to DeliverAI{' '}
            {(name ?? '').slice(0, 1).toUpperCase() + (name ?? '').slice(1)}!
            Please create a password to complete your registration.
          </p>
        </div>

        <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <DAIPasswordInput
              name="password"
              label="Password"
              register={register('password')}
              error={errors.password?.message}
              required
              showStrengthMeter
            />
            <DAIPasswordInput
              name="confirmPassword"
              label="Confirm Password"
              register={register('confirmPassword')}
              error={errors.confirmPassword?.message}
              required
            />
            <DAIButton
              buttonType="submit"
              text="Register"
              isLoading={submitMutation.isPending}
            />
          </form>
        </div>
      </div>
    </>
  )
}
