import { useCallback } from 'react'
import { Sale } from '../../types/Sale'
import { ModalProps } from '../../types/ModalProps'
import { useForm, Controller, ChangeHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import DAIModal from '../../DAIComponents/Modal/DAIModal'
import DAINumberInput from '../../DAIComponents/Input/Number/DAINumberInput'
import DAIDateInput from '../../DAIComponents/Input/Date/DAIDateInput'
import DAIButton from '../../DAIComponents/Input/Button/DAIButton'
import { DAIForm } from '../../DAIComponents/Form/DAIForm'
import DAIVirtualComboBox from '../../DAIComponents/Input/Combo/DAIVirtualComboBox'
import DAITextBox from '../../DAIComponents/Input/Text/DAITextBox'

const saleSchema = z.object({
  cost: z.number().min(0, 'Cost must be greater than or equal to 0'),
  createdAt: z.string(),
  invoiceNumber: z.string().optional(),
  contact: z.object({
    id: z.string(),
    name: z.string(),
  }),
  productCategory: z.object({
    id: z.string(),
    name: z.string(),
  }),
})

type SaleFormData = z.infer<typeof saleSchema>

interface CreateSaleModalProps extends ModalProps<Sale> {
  onSubmit: (data: Sale) => void
}

export default function CreateSaleModal({
  isOpen,
  closeModal,
  initialData,
  onSubmit,
}: CreateSaleModalProps) {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<SaleFormData>({
    resolver: zodResolver(saleSchema),
    defaultValues: initialData
      ? {
          cost: initialData.cost,
          createdAt: initialData.createdAt,
          invoiceNumber: initialData.invoiceNumber,
          contact: {
            id: initialData.contact.id,
            name: initialData.contact.name,
          },
          productCategory: {
            id: initialData.productCategory.id,
            name: initialData.productCategory.name,
          },
        }
      : undefined,
  })

  const handleFormSubmit = useCallback(
    async (data: SaleFormData) => {
      await onSubmit(data as Sale)
      closeModal()
    },
    [onSubmit, closeModal]
  )

  return (
    <DAIModal isOpen={isOpen} onClose={closeModal}>
      <div className="space-y-4">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {initialData ? 'Edit Sale' : 'Create Sale'}
        </h2>
        <DAIForm onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="contact"
              control={control}
              render={({ field }) => (
                <DAIVirtualComboBox
                  name="contact"
                  label="Contact"
                  required
                  error={errors.contact?.message}
                  field={field}
                  endpoint="/api/v1/contacts/basic"
                />
              )}
            />
            <Controller
              name="productCategory"
              control={control}
              render={({ field }) => (
                <DAIVirtualComboBox
                  name="productCategory"
                  label="Product"
                  required
                  error={errors.productCategory?.message}
                  field={field}
                  endpoint="/api/v1/product-categories/getMyProductCategoriesBasic"
                />
              )}
            />
            <DAITextBox
              name="invoiceNumber"
              label="Invoice Number"
              error={errors.invoiceNumber?.message}
              register={register("invoiceNumber")} />
            <DAINumberInput
              name="cost"
              label="Cost"
              required
              error={errors.cost?.message}
              register={register("cost")} />
            <DAIDateInput
              name="createdAt"
              label="Sale Date"
              required
              error={errors.createdAt?.message}
              register={register("createdAt")}
            />
            <div className="col-span-2 mt-5 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <DAIButton
                buttonType="submit"
                text={initialData ? 'Save Changes' : 'Create'}
                isLoading={isSubmitting}
                className="sm:col-start-2"
              />
              <DAIButton
                text="Cancel"
                variant="secondary"
                onClick={closeModal}
                className="mt-3 sm:col-start-1 sm:mt-0"
              />
            </div>
          </div>
        </DAIForm>
      </div>
    </DAIModal>
  )
}
