import React, { useEffect, useRef, useState, useCallback, forwardRef } from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import DAILabel from '../../Common/DAILabel';
import { textSchema } from '../../Base/validation';

type DAITextAreaProps = Omit<DAIInputComponentBase, 'type'> & {
  minRows?: number;
  maxRows?: number;
  autoResize?: boolean;
  maxLength?: number;
  showCharacterCount?: boolean;
  onSelect?: () => void;
};

const DAITextArea = forwardRef<HTMLTextAreaElement, DAITextAreaProps>(({
  name,
  label,
  register,
  required,
  error,
  disabled,
  placeholder,
  minRows = 3,
  maxRows = 10,
  autoResize = true,
  maxLength,
  showCharacterCount = false,
  className = '',
  flexDirection = 'col',
  onChange,
  onSelect,
}, ref) => {
  const [value, setValue] = useState('');
  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null);

  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const adjustHeight = useCallback(() => {
    if (textAreaRef) {
      textAreaRef.style.height = 'auto';
      textAreaRef.style.height = `${textAreaRef.scrollHeight}px`;
    }
  }, [textAreaRef]);

  const handleRef = (element: HTMLTextAreaElement | null) => {
    register.ref(element);
    setTextAreaRef(element);
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value, adjustHeight]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    onChange?.(e);
    adjustHeight();
  };

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={flexDirection === 'col' ? 'mt-2' : ''}>
        <textarea
          id={name}
          {...register}
          ref={handleRef}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          onChange={handleTextChange}
          onSelect={onSelect}
          rows={minRows}
          className={`${baseClasses} ${errorClasses} ${disabledClasses}`}
          style={{ resize: autoResize ? 'none' : 'vertical' }}
        />
        <div className="flex justify-between mt-1">
          {error && (
            <p className="text-sm text-red-600">{error}</p>
          )}
          {showCharacterCount && (
            <p className="text-sm text-gray-500">
              {value.length}
              {maxLength && ` / ${maxLength}`} characters
            </p>
          )}
        </div>
      </div>
    </div>
  );
});

export default DAITextArea; 