import { classNames } from '../../utils/className'
import CreateSaleModal from './CreateSaleModal'
import React, { useCallback, useState } from 'react'
import { Sale } from '../../types/Sale'
import useSaleContext from '../../hooks/useSaleContext'
import { useLocation } from 'react-router-dom'
import { convertToLocaleTime } from '../../utils/timezone'
import { Column, Table, TableButton } from '../../DAIComponents/Table/DAITable'
import RemoveModal from 'src/DAIComponents/Modal/RemoveModal'

export default function SalesTable() {
  const { sales, loading, onNameClick, deleteSale, createSale, editSale, fetchMoreSales, hasMore } = useSaleContext()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isNew = queryParams.get('new') === 'true'
  const [modelOpen, setModelOpen] = useState(isNew)
  const [initialData, setInitialData] = useState<Sale>()
  const [removeModal, setRemoveModal] = useState<boolean>(false)
  const [id, setId] = useState<string>('')

  const onEdit = useCallback(
    (id: string) => {
      const sale = sales.find(s => s.id === id)
      setInitialData(sale)
      setModelOpen(true)
    },
    [sales, setInitialData, setModelOpen]
  )

  const onClose = useCallback(() => {
    setModelOpen(false)
    setInitialData(undefined)
  }, [setModelOpen, setInitialData])

  const onOpen = useCallback(() => {
    setModelOpen(true)
  }, [setModelOpen])

  const onDelete = useCallback((id: string) => {
    setRemoveModal(true)
    setId(id)
  }, [])

  return (
    <>
      {modelOpen && (
        <CreateSaleModal
          isOpen={modelOpen}
          initialData={initialData}
          closeModal={onClose}
          onSubmit={initialData ? editSale : createSale}
          openModal={onOpen}
        />
      )}
      {removeModal && (
        <RemoveModal
          isOpen={removeModal}
          closeModal={() => {
            setRemoveModal(false)
            setId('')
          }}
          openModal={() => setRemoveModal(true)}
          onDelete={() => {
            deleteSale(id)
            setRemoveModal(false)
            setId('')
          }}
          title={'Delete Sale'}
          text={
            'Are you sure you want to remove this sale? ' +
            'All of the data associated with this sale will be permanently removed.'
          }
        />
      )}
      <Table
        loading={loading}
        onAdd={onOpen}
        data={sales}
        title={'Sales'}
        description={'A list of all your sales.'}
        onLoadMore={fetchMoreSales}
        hasMore={hasMore}
        maxHeight="70vh"
      >
        <Column title="Contact Name">
          {(sale: Sale) => (
              <TableButton title={sale.contact.name} onClick={() => onNameClick(sale.contact.id)} />
            )}
        </Column>
        <Column title="Sale Price">{(sale: Sale) => sale.cost}</Column>
        <Column title="Product Name">
          {(sale: Sale) => sale.productCategory.name}
        </Column>
        <Column title="Sale Date">
          {(sale: Sale) => convertToLocaleTime(sale.createdAt)}
        </Column>
        <Column title="">
          {(sale: Sale) => (
            <TableButton editButton={true} onClick={() => onEdit(sale.id)} />
          )}
        </Column>
        <Column title="">
          {(sale: Sale) => (
            <TableButton
              deleteButton={true}
              onClick={() => onDelete(sale.id)}
            />
          )}
        </Column>
      </Table>
    </>
  )
}
