import React from 'react'
import { SaleContextProvider } from 'src/contexts/saleContext'
import SalesTable from './SalesTable'

export default function Sales() {
  return (
    <SaleContextProvider>
      <div className="px-4 sm:px-6 lg:px-8">
        <SalesTable />
      </div>
    </SaleContextProvider>
  )
}
