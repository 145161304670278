export function convertToLocaleTime(utcTime?: string): string {
  if (!utcTime) return ''
  const date = new Date(utcTime)

  // return the date with the day and time only
  return date.toLocaleString('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
}

export function convertToLocaleDate(utcTime?: string): string {
  if (!utcTime) return ''
  const date = new Date(utcTime)
  return date.toLocaleDateString('en-US')
}

// TODO update
export function formatDateForInput(dateString?: string) {
  if (!dateString) return ''
  const date = new Date(dateString)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function formatISOToDate(isoString: string | undefined) {
  if (!isoString) return ''
  const date = new Date(isoString)
  const offset = date.getTimezoneOffset()
  const localDate = new Date(date.getTime() - offset * 60 * 1000)
  return localDate.toISOString().slice(0, 10) // Extracts 'YYYY-MM-DD'
}
