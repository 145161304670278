import React, { useCallback, useState } from 'react'
import { Product } from '../../types/Product'
import useProductContext from '../../hooks/useProductContext'
import { convertToLocaleTime } from '../../utils/timezone'
import { Column, Table, TableButton } from '../../DAIComponents/Table/DAITable'
import CreateProductModal from './CreateProductModal'
import RemoveModal from 'src/DAIComponents/Modal/RemoveModal'

export default function ProductsTable() {
  const { loading, products, deleteProduct } = useProductContext()
  const [modelOpen, setModelOpen] = useState(false)
  const [initialData, setInitialData] = useState<Product>()
  const [removeProductModal, setRemoveProductModal] = useState(false)
  const [id, setId] = useState<string>('')

  const onEdit = useCallback(
    (id: string) => {
      const product = products.find(s => s.id === id)
      setInitialData(product)
      setModelOpen(true)
    },
    [products, setInitialData, setModelOpen]
  )

  const onClose = useCallback(() => {
    setModelOpen(false)
    setInitialData(undefined)
  }, [setModelOpen, setInitialData])

  const onOpen = useCallback(() => {
    setModelOpen(true)
  }, [setModelOpen])

  const onDelete = useCallback((id: string) => {
    setRemoveProductModal(true)
    setId(id)
  }, [])

  return (
    <>
      {modelOpen && (
        <CreateProductModal
          isOpen={modelOpen}
          initialData={initialData}
          closeModal={onClose}
          openModal={onOpen}
        />
      )}
      {removeProductModal && (
        <RemoveModal
          isOpen={removeProductModal}
          closeModal={() => {
            setRemoveProductModal(false)
            setId('')
          }}
          openModal={() => setRemoveProductModal(true)}
          onDelete={() => {
            deleteProduct(id)
            setRemoveProductModal(false)
            setId('')
          }}
          title={'Delete Product'}
          text={
            'Are you sure you want to remove this product? ' +
            'All of the data associated with this product will be permanently removed.'
          }
        />
      )}
      <Table
        loading={loading}
        onAdd={onOpen}
        data={products}
        title={'Product Categories'}
        description={'A list of all your products categories.'}
      >
        <Column title="Product Title">
          {(product: Product) => product.name}
        </Column>
        <Column title="Created">
          {(product: Product) => convertToLocaleTime(product.createdAt)}
        </Column>
        <Column title="Updated At">
          {(product: Product) => convertToLocaleTime(product.updatedAt)}
        </Column>
        <Column title="">
          {(product: Product) => (
          product.organizationId && (
            <TableButton
                editButton={true}
                onClick={() => onEdit(product.id)}
                srValue={product.name}
              />)
          )}
        </Column>
        <Column title="">
          {(product: Product) => (
            product.organizationId && (
              <TableButton
                deleteButton={true}
                onClick={() => onDelete(product.id)}
                srValue={product.name}
              />)
          )}
        </Column>
      </Table>
    </>
  )
}
