import React, { forwardRef, HTMLInputTypeAttribute } from 'react'
import DAILabel from '../../Common/DAILabel'
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase'

type DAITextBoxProps = DAIInputComponentBase & {
  type?: HTMLInputTypeAttribute
}

const DAITextBox = forwardRef<HTMLInputElement, DAITextBoxProps>(({
  name,
  label,
  register,
  required,
  error,
  disabled,
  placeholder,
  type = 'text',
  className = '',
  flexDirection = 'col',
  onChange,
}, ref) => {
  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const handleRef = (element: HTMLInputElement | null) => {
    register.ref(element);
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }
  };
  
  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={flexDirection === 'col' ? 'mt-2' : ''}>
        <input
          id={name}
          type={type}
          {...register}
          ref={handleRef}
          required={required}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => onChange?.(e.target.value)}
          className={`${baseClasses} ${errorClasses} ${disabledClasses}`}
        />
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  )
});

export default DAITextBox;
