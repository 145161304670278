import React from 'react';
import { DAIInputComponentBase } from '../../Base/DAIInputComponentBase';
import DAILabel from '../../Common/DAILabel';
import { numberSchema } from '../../Base/validation';

type DAINumberInputProps = Omit<DAIInputComponentBase, 'type'> & {
  min?: number;
  max?: number;
  step?: number;
  prefix?: string;
  suffix?: string;
  allowDecimals?: boolean;
  decimalPlaces?: number;
};

export default function DAINumberInput({
  name,
  label,
  register,
  required,
  error,
  disabled,
  min,
  max,
  step = 1,
  prefix,
  suffix,
  allowDecimals = true,
  decimalPlaces = 2,
  className = '',
  flexDirection = 'col',
  onChange,
}: DAINumberInputProps) {
  const baseClasses = "block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6";
  const errorClasses = error ? "ring-red-300 text-red-900 focus:ring-red-500" : "ring-gray-300";
  const disabledClasses = disabled ? "bg-gray-50 text-gray-500 cursor-not-allowed" : "";

  const formatNumber = (value: number | string) => {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    if (isNaN(value)) return '';
    return allowDecimals
      ? value.toFixed(decimalPlaces)
      : Math.round(value).toString();
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    
    // Remove prefix and suffix if present
    if (prefix) value = value.replace(prefix, '');
    if (suffix) value = value.replace(suffix, '');
    
    // Allow only numbers and decimal point
    value = value.replace(/[^\d.-]/g, '');
    
    if (!allowDecimals) {
      value = value.replace(/\./g, '');
    }
    
    const numValue = parseFloat(value);
    if (!isNaN(numValue)) {
      if (min !== undefined && numValue < min) value = min.toString();
      if (max !== undefined && numValue > max) value = max.toString();
    }
    
    onChange?.(value);
  };

  return (
    <div className={`flex flex-${flexDirection} ${className}`}>
      {label && <DAILabel name={label} required={required} />}
      <div className={`relative ${flexDirection === 'col' ? 'mt-2' : ''}`}>
        {prefix && (
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500">
            {prefix}
          </span>
        )}
        <input
          id={name}
          type="number"
          {...register}
          required={required}
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          onChange={handleNumberChange}
          className={`
            ${baseClasses}
            ${errorClasses}
            ${disabledClasses}
            ${prefix ? 'pl-7' : ''}
            ${suffix ? 'pr-7' : ''}
          `}
        />
        {suffix && (
          <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500">
            {suffix}
          </span>
        )}
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
} 