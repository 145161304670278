import { useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { ModalProps } from '../../types/ModalProps'
import { TeamMember } from '../../types/Settings'
import { useMutation } from '@tanstack/react-query'
import { DAIRequest } from '../../utils/DAIRequest'
import { useNotifications } from '../../contexts/notificationContext'
import DAITextBox from '../../DAIComponents/Input/Text/DAITextBox'
import DAIEmailInput from '../../DAIComponents/Input/Email/DAIEmailInput'
import DAIButton from '../../DAIComponents/Input/Button/DAIButton'
import DAIComboBox from '../../DAIComponents/Input/Combo/DAIComboBox'
import DAIModal from '../../DAIComponents/Modal/DAIModal'
import { z } from 'zod'

const memberSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  email: z.string().email('Invalid email format'),
  role: z.object({
    id: z.string(),
    name: z.string()
  })
})

type MemberForm = z.infer<typeof memberSchema>

export default function CreateMemberModal(props: ModalProps<TeamMember>) {
  const { register, handleSubmit, control, formState: { errors, isSubmitting } } = useForm<MemberForm>({
    resolver: zodResolver(memberSchema)
  })
  const { addNotification } = useNotifications()

  const inviteMutation = useMutation({
    mutationFn: async (data: {
      firstName: string
      lastName: string
      email: string
      role: string
    }) => {
      await DAIRequest('post', '/api/v1/authentication/inviteUser', {
        body: data,
      })
    },
    onSuccess: () => {
      addNotification('success', 'User invited successfully')
      props.closeModal()
    },
    onError: () => {
      addNotification('error', 'Failed to invite user')
    },
  })

  const onSubmit = useCallback(
    (data: MemberForm) => {
      inviteMutation.mutate({
        ...data,
        role: data.role.name.toUpperCase(),
      })
    },
    [inviteMutation]
  )

  return (
    <DAIModal isOpen={props.isOpen} onClose={props.closeModal}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Member Information
            </h2>
            <p className={'mt-1 text-sm text-gray-700'}>
              This will send an email invitation to the new member.
            </p>
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <DAITextBox
                  name="firstName"
                  label="First name"
                  register={register('firstName')}
                  error={errors.firstName?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAITextBox
                  name="lastName"
                  label="Last name"
                  register={register('lastName')}
                  error={errors.lastName?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <DAIEmailInput
                  name="email"
                  label="Email address"
                  register={register('email')}
                  error={errors.email?.message}
                  required
                />
              </div>

              <div className="sm:col-span-3">
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <DAIComboBox
                      name="role"
                      label="Role"
                      data={[
                        {
                          id: '1',
                          name: 'Admin',
                        },
                        {
                          id: '2',
                          name: 'User',
                        },
                      ]}
                      field={field}
                      error={errors.role?.message}
                      required
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <DAIButton
            buttonType="button"
            text="Cancel"
            variant="secondary"
            onClick={props.closeModal}
          />
          <DAIButton
            buttonType="submit"
            text="Submit"
            variant="primary"
            isLoading={isSubmitting}
          />
        </div>
      </form>
    </DAIModal>
  )
}
